'use client';

import styles from './project.module.scss'

import { useState } from 'react';

import Project from '../component/project/Project'

import Modal from '../component/modal/Modal'


const projects = [
  {
    title: "Giovanni's",
    src: "giovanni.png",
    color: "#000000",
    link: "https://giovanni-zeta.vercel.app/" // Add your link here
  },
  {
    title: "Portfolio",
    src: "portfolio.gif",
    color: "#8C8C8C",
    link: "" // Add your link here
  },
  {
    title: "Piscina Barolo",
    src: "piscina.gif",
    color: "#EFE8D3",
    link: "" // Add your link here
  },
  {
    title: "Silencio",
    src: "creating.png",
    color: "#706D63",
    link: "" // Add your link here
  }
];




export default function Projects() {



  const [modal, setModal] = useState({active: false, index: 0})



  return (

  <section className={styles.main} id="Projects">

    <div className={styles.body}>
      <h1>
        Projects
      </h1>

      {

        projects.map( (project, index) => {

          return <a href={project.link} rel="noreferrer" target="_blank"><Project index={index} title={project.title} setModal={setModal} key={index} /></a>

        })

      }

    </div>

    <Modal modal={modal} projects={projects}/>

  </section>

  )

}



























// function Projects(){
//     return(
//         <section id="Projects">
//         <h1> Projects</h1>
//         <div className="container">
//             <div className="card">
//                 <div className="cardImage" id="proj1">
//                 </div>
//                 <div className="cardTitle">
//                     <h1>Acquavitae Piscina</h1>
//                     <p>Promotional website for local swimming pool produced in 2018.</p>
//                 </div>
//             </div>
//             <div class="card">
//                 <div className="cardImage" id="proj2">
//                 </div>
//                 <div className="cardTitle">
//                     <h1>Personal Portfolio</h1>
//                     <p>Previous version of my portfolio produced in 2019 for an assignment in web Production.</p>
//                 </div>
//             </div>
           
            
//             <div className="card">
//                 <div className="cardImage" id="proj3">
//                 </div>
//                 <div className="cardTitle">
//                     <h1>Projects coming soon..</h1>
//                     <p>I am workiing to produce some modern and functional projects, come back soon for a demo. </p>
//                 </div>
//             </div>
//         </div>
//     </section>
//     )
// }

// export default Projects