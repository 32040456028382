
import { useEffect, useRef } from "react";
import Typed from "typed.js"




function Home () {


    const el = useRef(null);

    useEffect(() => {
      const typed = new Typed(el.current, {
        strings : ["Thomas.","a Full-Stack developer.","a Software Engineer.","Professional, Reliable and Creative.","Designer.","🔥."],
        typeSpeed : 80,
        backSpeed : 50,
        loop : true
      });
      // Destropying
    return () => {
        typed.destroy();
      };
    }, []);

    return(
        
        <section id="Home">
            
            <h1> <span>&#123; </span> I'm <span  ref={(el)}></span> &#125;</h1>
            <p>
                <i className="fa-brands fa-js" ></i>
                <i className="fa-brands fa-react"></i>
                <i className="fa-brands fa-html5" ></i>
                <i className="fa-brands fa-css3-alt" ></i>
                <i className="fa-brands fa-java" ></i>
                <i className="fa-brands fa-python" ></i>
                <i className="fa-brands fa-node-js" ></i>
            </p>
        
        </section>
    );
}


export default Home;