import React from 'react';

function Socials(){
    return(
        <div className="float-sm">
            <div className="fl-fl float-fi">
                <a href="/images/ThomasLucaTamagni_CV.pdf" target="">
                <i className="fa fa-file"></i>
                Get resume!</a>
            </div>
            <div className="fl-fl float-git">
                <a href="https://github.com/tommy812" target="_blank" rel="noreferrer">
                    <i className="fa fa-brands fa-github"></i>
                Follow me!</a>
            </div>
            <div className="fl-fl float-in">
                <a href="https://www.linkedin.com/in/thomas-tamagni-71b25719a" target="_blank" rel="noreferrer">
                    <i className="fa fa-linkedin"></i>
                Follow me!</a>
            </div>
         </div>
    )
    
}

export default Socials;
