import React from "react"
import { motion } from "framer-motion";
import { useState } from "react";
import useMousePosition from "../../utils/useMousePostiion";

function Cursor() {
    const [isHovered, setIsHovered] = useState(false);
    const{x,y} = useMousePosition(); 
    const size = isHovered ? 300 : 30;

    return(
       <motion.div 
       className="mask"
       animate={{
        WebkitMaskPosition: `${x - size/2 }px ${y -size/2 + window.scrollY}px`,
        WebkitMaskSize: `${size}px`
       }}
       transition={{type:"tween", ease: "backOut"}}>
        <h1 onMouseEnter={() => {setIsHovered(true)}} onMouseLeave={() => {setIsHovered(false)}}>Did you say <span>creative</span> solutions?</h1>
       </motion.div>
    );
}
export default Cursor