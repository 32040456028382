import Header from './sections/Header'
import Socials from './sections/Socials'
import Home from './sections/Home'
import About from './sections/About'
import Project from './sections/projects/Page'
import Contacts from './sections/Contacts'
import Footer from './sections/Footer'
import Preloader from './sections/Preloader/index'
import Cursor from './sections/component/Cursor';
import { useEffect, useState } from 'react';
import { AnimatePresence } from "framer-motion"
import {isMobile} from 'react-device-detect';

function App() {
   const [isLoading, setIsLoading] = useState (true);
  
   useEffect( () => {
      (
         async () => {
            setTimeout( ()=> {
               setIsLoading(false);
               document.body.style.cursor = "default"
               window.scrollTo(0,0);
            }, 2000)
            setTimeout( ()=> {
               const headerElement = document.getElementById('header');
               if (headerElement) {
                  headerElement.style.display = 'flex';
               }
            }, 2500)
         }
      )()
   }, [])
  return (
     <>
         <AnimatePresence mode='wait'>
            {isLoading && <Preloader />}
         </AnimatePresence>
         {!isMobile && <Cursor />}
         <Header />
         <Socials />
         <Home />
         <About />
         <Project/>
         <Contacts />
         <Footer />
     </>
  );
}

export default App;
