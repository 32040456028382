function About() {
    return(
        <section id="About">
        
            <div className="container">
                <h1 className="title"><span>Get to Know more <br/> </span>About Me</h1>
                <div className="content">
                    <div class="profilePic">
                        <img src="images/profilepic.jpeg" alt="Profile" class="picture" />
                    
                    </div>
                    <div className="description">
                        <h1 className="greet">Hi there 👋 <br /> <span>I'm Thomas Tamagni</span></h1>
                        
                        <p className="text">I am deeply passionate about developing and managing systems to solve complex problems, improve businesses' efficiency, and enhance their image.<br/>
                            I truly appreciate challenges and the research for innovation. <br/><br/> 
                        </p>
                        <div className="cardsContainer">
                            <div className="card">
                                <i className="fa-solid fa-building"></i>
                                <h3>Academic<br/>Experience</h3>
                                <p>5+ Years Developing</p>
                            </div>
                            <div class="card">
                                <i class="fa-solid fa-building-columns"></i>
                                <h3>Education</h3>
                                <p>BEng (Hons)<br/>Computer Science</p>
                            </div>
                            <div class="card">
                                <i class="fa-solid fa-building-columns"></i>
                                <h3>Education <br/></h3>
                                <p>MSc <br/>Software Engineering</p>
                            </div>
                        </div>
                    
                    

                    </div>
                
                </div>
            
            </div>
        </section>
    )
}
    

export default About;